import React from "react";
import ReactDOM from "react-dom/client";

export function Introduction () {
    return (
        <section id="about" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" aria-label="About me">
            <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-neutral-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">About</h2>
            </div>
            <div className="text-left">
                <p className="mb-4">
                    I am currently a graduate student at Northwestern University.
                </p>
                <p className="mb-4">
                    Outside of school, I play video games 🕹️, enjoy language learning, and love sports like table
                    tennis 🏓, basketball 🏀, and soccer (KTBFFH!) ⚽🦁.
                </p>
                <p>
                    I'm interested in all things Artificial Intelligence, Machine Learning, Finance & Trading, Security, and Software. I always strive to bring energy, technical knowledge, and a strong
                    desire to learn with me wherever I go.
                </p>
            </div>
        </section>
    )
}