import React from "react";
import ReactDOM from "react-dom/client";
import {Introduction} from "./Introduction";
import {Experience} from "./Experience";
import {Projects} from "./Projects";
import {School} from "./School";
import {Education} from "./Education";
import {FooterMessage} from "./FooterMessage";
import {CourseworkLink} from "./CourseworkLink";
import {ResumeLink} from "./ResumeLink";
export function Content () {
    return (
        <div className="pt-24 lg:w-1/2 lg:py-24">
            <Introduction />
            <Experience />
            <ResumeLink />
            <Education />
            <CourseworkLink />
            <Projects />
            <FooterMessage />
        </div>
    )
}