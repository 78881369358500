import React from "react"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

export function ResumeLink () {
    return (
        <section id="coursework" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24">
            <div className="text-left">
                <a className="inline-flex items-center font-medium leading-tight text-slate-200 font-semibold text-slate-200 group" aria-label="View Full Project Archive" href = "./Resume.pdf" target = "_blank">
                    {/* <a className="inline-flex items-center font-medium leading-tight text-slate-200 font-semibold text-slate-200 group" aria-label="View Full Project Archive">  */}
                    <span>
                        <span className="border-b border-transparent pb-px transition group-hover:border-neutral-300 motion-reduce:transition-none">
                        View Resume
                        </span>
                        <span className="whitespace-nowrap">
                            <span className="border-b border-transparent pb-px transition group-hover:border-neutral-300 motion-reduce:transition-none">

                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="ml-1 inline-block h-4 w-4 shrink-0 -translate-y-px transition-transform group-hover:translate-x-2 group-focus-visible:translate-x-2 motion-reduce:transition-none" aria-hidden="true">
                                <path fillRule="evenodd"
                                             d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                                             clipRule="evenodd">

                                </path>
                            </svg>
                        </span>
                    </span>
                </a>
            </div>
        </section>
    )
}


