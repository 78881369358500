import logo from './logo.svg';
import './App.css';
import {Nameplate} from "./components/Nameplate";
import {Content} from "./components/Content";
import colors from "tailwindcss/colors";
import { inject } from '@vercel/analytics';
import { BrowserRouter as Router, Route, Switch, Link, Routes } from "react-router-dom";
import {Coursework} from "./components/Coursework";



function App() {

  inject();

  return (
      <Router>
          <div className="App">
              <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0 bg-neutral-900 leading-relaxed text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900">
                  <div className="lg:flex lg:justify-between lg:gap-4">
                      <Routes>
                          <Route path="/" element={<><Nameplate/><Content /></>}/>
                          <Route path="/coursework" element={<Coursework />}/>
                      </Routes>
                  </div>
              </div>
          </div>
      </Router>
  );
}

export default App;
