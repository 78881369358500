import React from "react";
import ReactDOM from "react-dom/client";
import tardis from "../img/tardis.gif"

export function FooterMessage () {
    return (
        <section id="about" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" aria-label="About me">
            <div>
                <p className="mb-4 text-left">
                    Coded in WebStorm by yours truly. Built with ReactJS, React-Router-DOM, and Tailwind CSS, deployed with Vercel.
                </p>
                <div className="float-right" >
                    <img alt="Spinning Tardis from Doctor Who" loading="lazy" decoding="async" data-nimg="1" width="100" height="86" src={tardis} />
                </div>
            </div>
        </section>
    )
}