import React from "react";
import ReactDOM from "react-dom/client"

export function Tag ({tagName}) {
    return (
        <li className="mr-1.5 mt-2">
            <div className="flex items-center bg-neutral-400/10 px-3 py-1 text-xs font-medium leading-5 text-neutral-300 ">
                {tagName}
            </div>
        </li>
    )
}