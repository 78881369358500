import React from "react";
import {ProjectItem} from "./ProjectItem";
import {ProjectPreviewItem} from "./ProjectPreviewItem"

export function Projects () {
    return (
        <section id="projects" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24">
            <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-neutral-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Projects</h2>
            </div>
            <div>
                <ol className="group/list">
                    <ProjectPreviewItem
                        projectTitle="CadetCite"
                        projectLink="https://cadetcite.vercel.app"
                        description="Website for West Point Cadets to appropriately document academic work."
                        preview="./cadetcite.png"
                        tags={["ReactJS", "NextJS", "Svelte", "TailwindCSS","FlowbiteCSS","Vercel"]}
                    />
                    <a href = "./ReleaseMechanismPoster.pdf" target = "_blank">
                        <ProjectItem
                            title="Release Mechanism for Wind Tunnel Testing of Autonomous Loitering Effects"
                            timeDate="August 2022 - May 2023"
                            description="Collaboration with DEVCOM Experimental Aero Engineers to design,
                            develop, build and test ALE in launch configurations/profiles.
                            Served as project lead. Poster produced and presented during USMA's projects day. Report written with intent to publish and produce patent. Abstract accepted by AIAA. "
                            tags={["Aeronautics", "Material Science", "SolidWorks", "Python", "MATLAB","Machine Design", "OpenCV", "Phantom"]}
                        />
                    </a>
                    <a href = "./FutureTransportation.pdf" target = "_blank">
                        <ProjectItem
                            title="Future Transportation"
                            timeDate="August 2021 - December 2021"
                            description="The purpose of this study was to better understand the influence of the
                            three revolutions of transportation: electrifications, automation, and on demand
                            services (MaaS). A particular focus was placed on MaaS and electrification to better
                            understand the implications for energy consumption and emissions of the national
                            vehicle fleet. One of the goals of this project is to help understand some of the
                            near-term transportation trends that could impact automotive manufacturing in the
                            United States and subsequently influence both the non-tactical and tactical vehicles
                            that are available for the Army."
                            tags={["MaaS", "Clean Energy", "Python", "Data Analysis"]}
                        />
                    </a>
                    <a href = "./ClassroomContaminantDispersion.pdf" target = "_blank">
                        <ProjectItem
                            title="Classroom Contaminant Dispersion"
                            timeDate="August 2020 - January 2021"
                            description="The purpose of this project was to analyze the effect that classroom
                            orientation has on the airflow in the room in order to help answer whether there is
                            an ideal classroom location to minimize aerosol loading on an instructor in order to
                            best simulate the traditional classroom environment in the midst of COVID 19."
                            tags={["COVID-19", "Fluids", "MATLAB", "Python", "Solidworks", "Research", "Experiment Design"]}
                        />
                    </a>
                </ol>
            </div>
        </section>
    )
}