import React from "react";
import {ExperienceItem} from "./ExperienceItem";


export function Experience () {
    return (
        <section id="experience" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-4 lg:scroll-mt-24">
            <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-neutral-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Experience</h2>
            </div>
            <div>
                <ol className="group/list">
                    <ExperienceItem
                        title="AIAD Intern"
                        timeDate="2023"
                        organization="Boeing"
                        position="Internship"
                        description="Bell Boeing V-22 Osprey Modernization Team"
                        tags={["Aeronautics", "Business Development", "Rotorcraft"]}
                    />
                    <ExperienceItem
                        title="AIAD Intern"
                        timeDate="2023"
                        organization="Combined Space Operations Center (CSpOC)"
                        position="Internship"
                        description="Army, Navy, USMC, and Space Force space capabilities and operations."
                        tags={["Aeronautics", "Space", "Military"]}
                    />
                    <ExperienceItem
                        title="AIAD Intern"
                        timeDate="2022"
                        organization="Bell Flight"
                        position="Internship"
                        description="Rotor and Drive System Project for Advanced Technology Innovation."
                        tags={["Aeronautics", "Innovation", "Rotorcraft"]}
                    />
                    <ExperienceItem
                        title="Mechanical Engineering Summer Research Intern"
                        timeDate="2017"
                        organization="University of Houston"
                        position="Internship"
                        description="Under the mentorship of Dr. Hadi Ghasemi and Peyman Irajizad.
                        In this position, I helped in a project concerning perform fundamental studies and
                        development of anti-icing surfaces. These surfaces play a vital role in
                        energy systems, infrastructures, power transmission systems and aeronautics."
                        tags={["Material Science", "Research"]}
                    />
                </ol>
            </div>
        </section>
    )
}
