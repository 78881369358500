import React from "react"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import {CourseItem} from "./CourseItem";


export function Coursework () {
    return (
        <div className="lg:py-24">
            <Link to="/">
                <a className="group mb-2 inline-flex items-center font-semibold leading-tight text-neutral-300" href="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     className="mr-1 h-4 w-4 rotate-180 transition-transform group-hover:-translate-x-2" aria-hidden="true">
                    <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd">
                    </path>
                </svg>
                Victor Apanpa
                </a>
                <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
                    Coursework
                </h1>
            </Link>
            <table id="content" className="mt-12 w-full border-collapse text-left">
                <thead className="sticky top-0 z-10 border-b border-slate-300/10 bg-neutral-900/75 px-6 py-5 backdrop-blur">
                    <tr>
                        <th className="py-4 pr-8 text-sm font-semibold text-slate-200">Course Code</th>
                        <th className="py-4 pr-8 text-sm font-semibold text-slate-200">Title</th>
                        <th className="hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell">Type</th>
                        <th className="hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <CourseItem
                        courseCode="MC300"
                        title="Fundamentals of Engineering Mechanics and Design"
                        tags={["Mechanical Engineering"]}
                        courseDescription="Engineering design process and the method of design are introduced. Principles of equilibrium are used to analyze forces on statically determinate rigid bodies and structures to include trusses and frames. The behavior of deformable bodies under axial and flexural loading is examined. The concepts of stress, strain, and material properties are introduced and are used to relate external forces applied to a body to the resulting internal forces and deformations so that performance can be evaluated. Practical applications involving the design and adequacy of mechanical and structural elements under various loading conditions are emphasized."
                    />
                    <CourseItem
                        courseCode="ME201"
                        title="Introduction to Mechanical Engineering"
                        tags={["Mechanical Engineering"]}
                        courseDescription="This course introduces product design as an iterative decision making process. It also introduces project planning and team dynamics, technical communications, and using computing tools including structured programming and 2-D/3-D visualization and analysis to aid the design process. Cadets solve a series of progressively more challenging problems by applying the design process while addressing social, political, economic and technical issues."
                    />
                    <CourseItem
                        courseCode="MC364"
                        title="Mechanics of Materials"
                        tags={["Mechanical Engineering"]}
                        courseDescription="This course studies the behavior of a variety of materials under normal, shear, torsional, bending and combined loads. The concepts of stress, strain, failure theory and failure mechanisms are explored. The loading, geometry, functional environment and material properties of machine or structural parts are used to relate the forces applied to a body to the resulting internal forces and deformations so that performance can be evaluated. Practical applications involving the design and adequacy of mechanical and structural elements under various loading and environmental conditions are emphasized. The course includes several laboratory experiences that require students to develop and conduct experiments, analyze and interpret data, and use engineering judgement to draw conclusions."
                    />
                    <CourseItem
                        courseCode="ME370"
                        title="Computer Aided Design"
                        tags={["Mechanical Engineering"]}
                        courseDescription="Explores the use of computer methods as an aid to solving engineering problems. Computer techniques are studied in a variety of engineering contexts. Topics include 3D solid modeling, engineering analysis, engineering computer programming, and graphical presentation of information. Students learn to apply a variety of engineering-related programs or routines. Students write, document, and use programs of their own in design scenarios. Considerable emphasis is placed on use of the computer as a tool in the engineering design process."
                    />
                    <CourseItem
                        courseCode="EE301"
                        title="Fundamentals of Electrical Engineering"
                        tags={["Electrical Engineering"]}
                        courseDescription="This introductory course in electrical engineering for the non-electrical engineering major provides a foundation in basic circuit theory and analysis, power in circuits and electric power systems, analog and digital electronics, and information technology systems. Lectures, laboratory work, practical applications, and classroom demonstrations emphasize and illustrate the fundamental theories and concepts presented in the course. Engineering design is reflected in laboratory work and minor design problems."
                    />
                    <CourseItem
                        courseCode="MA365"
                        title="Advanced Math for Engineers/Scientists"
                        tags={["Mechanical Engineering"]}
                        courseDescription="This is a course designed for the advanced mathematics student that has completed courses in differential equations and vector calculus (those that have completed MA153 and MA255) and will study ME, EE, NE, Physics or Space Science. MA365 begins where the advanced mathematics program ends. The advanced engineering course offering includes topics in linear algebra, complex variables, Fourier series, partial differential equations, and computational mathematics."
                    />
                    <CourseItem
                        courseCode="MC306"
                        title="Dynamics"
                        tags={["Mechanical Engineering"]}
                        courseDescription="Dynamics examines the motion of particles, systems of particles, and rigid bodies under the influence of forces. It focuses on the use of Newton's Second Law, in three major, progressive blocks of instruction: from scalar, then vector, treatments of rectilinear and curvilinear motion of single particles; through vector motion of systems of particles; to general three-dimensional motion of rigid bodies. The course also provides brief introductions to energy methods: work-energy and impulse-momentum."
                    />
                    <CourseItem
                        courseCode="MC311"
                        title="Thermal-Fluid Systems I"
                        tags={["Mechanical Engineering"]}
                        courseDescription="Thermal-Fluid Systems I is an integrated study of fundamental topics in thermodynamics and fluid mechanics. The course introduces conservation principles for mass, energy, and linear momentum as well as the 2nd Law of Thermodynamics. Principles are applied to incompressible flow in pipes and turbomachinery, power generation systems, refrigeration cycles, and total air-conditioning focusing on the control volume approach. Laboratory exercises are integrated into classroom work. This course includes completion of a comprehensive, out-of-class design problem. This design problem provides the opportunity for students to apply engineering science and the engineering design process to a hands-on project. Lesson length varies between 75 minutes and 55 minutes (and corresponding preparation time varies as well)."
                    />
                    <CourseItem
                        courseCode="MC312"
                        title="Fluid Mechanics"
                        tags={["Mechanical Engineering"]}
                        courseDescription="Fluid Mechanics is the study of the behavior of liquids and gases under all conditions of rest and motion. The basic conservation laws are developed and applied to problems encountered in stream and pipe flow, modeling, low speed aerodynamics, forces on submerged surfaces, turbomachinery, and flow measurement. The course emphasizes physical concepts as well as mathematics and is augmented by laboratory work and a wide variety of training aids. This course provides the foundation for further study in aerodynamics, energy systems engineering, automotive engineering, civil engineering, and environmental engineering."
                    />
                    <CourseItem
                        courseCode="ME403"
                        title="Manufacturing/Machine Component Design"
                        tags={["Mechanical Engineering"]}
                        courseDescription="This course introduces manufacturing machines and machine component design. Students develop their understanding of the engineering science behind static and fatigue failure theories and use it to design or choose mechanical components such as fasteners, springs, bearings, gears, and shafts. Instructors and laboratory technicians train cadets on safe use of machines such as mills, lathes, grinders, belt sanders, drill presses, bandsaws, and welding equipment. The course culminates in a team design and manufacturing project that uses the technical engineering and hands-on skills learned throughout the semester."
                    />
                    <CourseItem
                        courseCode="XE472"
                        title="Dynamic Modeling and Control"
                        tags={["Mechanical Engineering", "Electrical Engineering"]}
                        courseDescription="This course covers dynamic modeling and control of linear systems. The course provides an overview of classical control theory as the foundation for control applications in electrical, mechanical, and aeronautical systems. Topics here include system modeling using Laplace transform, frequency domain, and state variable methods. Mathematical models are developed for electrical, mechanical, aeronautical, chemical and other physical control systems. Control systems analysis and design techniques are studied within the context of how each system is physically controlled in practice. Laboratory exercises include feedback design and system identification. Computer design exercises include dynamic modeling and control of various engineering systems."
                    />
                    <CourseItem
                        courseCode="EM381"
                        title="Engineering Economy"
                        tags={["Gen. Eng"]}
                        courseDescription="This course prepares cadets to consider the economic dimension in the evaluation of engineering alternatives; a consideration vital to the Systems Decision Process, engineering management, systems acquisition and many other application areas. While emphasis is on the analytical consideration of money and its impact on the areas above, the course also incorporates professional ethics in the engineering economic analysis process. The course is taught in four lesson blocks. The Time Value of Money (TVM) block -includes the quantitative methods for economic analysis of engineering alternatives by introducing cost concepts, interest concepts, the cash flow diagram and developing interest formulas. The Analysis Methods block develops techniques for project evaluation and comparison and ways to account for risk and uncertainty. The After Tax Cash Flow block incorporates the real-world effect of taxes, depreciation and inflation into the analysis methods. The Capital Budgeting block completes a comprehensive introduction to engineering economy by introducing the concept of economic service life and project financing. A one lesson introduction to personal finance is included to demonstrate how many of the concepts used in the business world can also be applied for personal planning. Course concepts are applied using Excel in both graded and ungraded labs. Cadets will spend several lessons in a computer lab environment."
                    />
                    <CourseItem
                        courseCode="ME387"
                        title="Introduction to Aeronautics"
                        tags={["Mechanical Engineering"]}
                        courseDescription="This introductory course provides an understanding of how fixed-wing aircraft work and the forces and airflow involved in flight. Equations of motion are derived for a rigid aircraft in steady state level flight, maneuvering flight, and during takeoff and landing. Theoretical concepts are demonstrated in laboratory sessions that include actual flights in the Department of Civil and Mechanical Engineering's fixed-wing aircraft, and the evolution of flight during a museum field trip."
                    />
                    <CourseItem
                        courseCode="ME480"
                        title="Heat Transfer"
                        tags={["Mechanical Engineering"]}
                        courseDescription="The three modes of heat transfer, conduction, convection, and radiation, are studied in detail and applications are made to various engineering systems. The principles of conduction and convection are used to study the mechanisms of heat transfer during boiling, condensation and the design of heat exchangers."
                    />
                    <CourseItem
                        courseCode="XE475"
                        title="Mechatronics"
                        tags={["Mechanical Engineering", "Electrical Engineering"]}
                        courseDescription="XE 475 is a comprehensive introductory course in the field of mechatronics. Mechatronics is the crossroads in engineering where mechanical engineering, electrical engineering, computer science, and controls engineering meet to create new and exciting real-world systems. Knowledge of mechanical and electrical components, controls theory, and design are integrated to solve actual physical design applications."
                    />
                    <CourseItem
                        courseCode="CS384"
                        title="Data Structures"
                        tags={["Computer Science"]}
                        courseDescription="This course is designed to build on the cadet's basic programming knowledge. Major emphasis is placed on object-based design, programming methodology, algorithms and algorithm analysis, data structures, and abstract data types as tools for the analysis, design, and implementation of software modules to meet specified requirements. Cadets will learn and employ several well-known algorithms and data structures. Techniques of searching, sorting, recursion, and hashing will be examined. Data structures such as sets, heaps, linked lists, stacks, queues, and trees will be covered. A block-structured programming language reflecting comprehensive support for good software engineering principles will be the foundation of application-oriented exercises. Cadets will design software solutions by employing problem decomposition and selecting the appropriate algorithms and abstract data types."
                    />
                    <CourseItem
                        courseCode="CS393"
                        title="Database Systems"
                        tags={["Computer Science"]}
                        courseDescription="This course addresses the analysis, design implementation, and management of relational databases. Structured query language (SQL) is covered in depth along with standard problem domain and data modeling techniques. Implementation techniques and considerations are discussed and practiced extensively. Key concepts include analysis and design using a standardized notation, data model to logical schema conversion techniques, normalization, data security, client-server architectures and web-based access to database systems. Students are exposed to user and privilege management, database backup and recovery, and security vulnerabilities such as SQL injection."
                    />
                    <CourseItem
                        courseCode="MA486"
                        title="Mathematical Computation"
                        tags={["Mathematics"]}
                        courseDescription="This course provides an undergraduate presentation of computational problem solving that builds on math and science classes from USMA. The emphasis of this course is on developing a conceptual understanding of the fundamental topics in algorithm development as well as developing an understanding of a high level programming language. MA486 exposes students to mathematical applications best understood through computational analysis. Contemporary topics of application may include linear algebra, numerical analysis, statistics, data analytics and visualization, simulation, optimization, and machine learning. Students will be exposed to topics in computer science including data structures, object oriented programming, debugging, complexity, as well as testing and verification. We will use a high level computer programming language such as R or Python to explore and expose various topics."
                    />
                    <CourseItem
                        courseCode="CY350"
                        title="Computer Networks"
                        tags={["Computer Science"]}
                        courseDescription="This course provides cadets with an introduction to computer networks by breaking the subject into comprehensible parts and building a survey of the state of the art. The goal of the course is to provide each cadet with basic concepts necessary to understand the design and operation of computer networks. Taking a layered approach, it examines the Internet with an emphasis on the TCP/IP protocol suite. Additionally, basic principles including multiplexing, switching, flow control, and error control are covered. Internetworking and its application to both local and wide area networks are also investigated. The course offers an understanding of the current status and future directions of technology and how technology relates to standards."
                    />
                    <CourseItem
                        courseCode="EE360"
                        title="Digital Logic w/ Embedded Systems"
                        tags={["Electrical Engineering"]}
                        courseDescription="This course covers the analysis, design, simulation, and construction of digital logic circuits and embedded systems. The material in this course provides the necessary tools to design digital hardware circuits based on design techniques such as Karnaugh maps and Finite State Machines. The course begins with the study of binary and hexadecimal number systems, Boolean algebra, and their application to the design of combinational logic circuits. The first half of the course focuses on designs using medium-scale integration (MSI) circuits and Field Programmable Gate Arrays (FPGAs) to implement combinational logic functions. The second half of the course emphasizes sequential logic circuits. Laboratory work in this half of the course focuses on using very high speed integrated circuit hardware description language (VHDL) to simulate digital systems and to program those systems in hardware. As a final project, cadet teams design, build, and test a digital logic system."
                    />
                </tbody>
            </table>
        </div>
    )
}