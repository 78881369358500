import React from "react";
import {Tag} from "./Tag";

export function CourseItem ({courseCode, title, courseDescription, tags}) {

    const tagSet=tags && tags.map(
        tagItem => <Tag
            key={tagItem} tagName={tagItem}
        />
    )

    return (
        <tr className="border-b border-slate-300/10 last:border-none">
            <td className="py-4 pr-4 align-top text-sm">
                <div className="translate-y-px">{courseCode}</div>
            </td>
            <td className="py-4 pr-4 align-top font-semibold leading-snug text-slate-200">
                <div>
                    <div className="hidden sm:block">{title}</div>
                </div>
            </td>
            <td className="hidden py-4 pr-4 align-top lg:table-cell">
                <ul className="flex -translate-y-1.5 flex-wrap">
                    {tagSet}{/*{tagSet}*/}
                </ul>
            </td>
            <td className="hidden py-4 align-top sm:table-cell">
                {courseDescription}
            </td>
        </tr>
    )
}